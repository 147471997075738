import React, { Fragment } from 'react'
import MBContainer from './MessageBoard/MBContainer'
import CWContainer from './CodeWindow/CWContainer'
import MessageProvider from '../../contexts/MessageCtx'

const Page = () => {

    return (
        <Fragment>
            <MessageProvider>
                <MBContainer key='MBContainer' />
            </MessageProvider>
            <CWContainer key='CWContainer' />
        </Fragment>
    )
}

export default Page