import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom'
import './index.css';
import App from './App';
import StateProvider from './contexts/State'
import './fonts/SourceCodePro-Regular.woff2'
import './fonts/Quicksand-Regular.woff2'
import './fonts/Quicksand-Bold.woff2'
import './fonts/Quicksand-Light.woff2'
import './fonts/Quicksand-SemiBold.woff2'
import './fonts/Quicksand-Medium.woff2'
import './fonts/Comfortaa-Regular.woff2'
import './fonts/Comfortaa-Bold.woff2'
import './fonts/Comfortaa-Light.woff2'
import './fonts/Comfortaa-SemiBold.woff2'
import './fonts/Comfortaa-Medium.woff2'


ReactDOM.render(
  
        <BrowserRouter>
            <StateProvider>
                <App />
            </StateProvider>
        </BrowserRouter>
  ,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA

