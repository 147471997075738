import React from 'react'

const MBDateLine = (props) => {

    const { mappedDate } = props

    return (
        <div className='DateLineWrapper FlexCenter'>
            <svg height='100%' width='40%'>
                <line x1='0' y1='50%' x2='100%' y2='50%' style={{stroke:'#999999', strokeWidth:1}} />
            </svg>
            <div className='DateLineItem FlexCenter'>{mappedDate}</div>
            <svg height='100%' width='40%'>
                <line x1='0' y1='50%' x2='100%' y2='50%' style={{stroke:'#999999', strokeWidth:1}} />
            </svg>
        </div>
    )
}

export default MBDateLine