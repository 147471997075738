import reactLogo from '../../assets/react.svg'
import jsLogo from '../../assets/javascript.svg'
import cssLogo from '../../assets/css.svg'
import mongoLogo from '../../assets/mongodb.svg'
import htmlLogo from '../../assets/html5.svg'
import nodeLogo from '../../assets/nodejs.svg'

export const logoItems = [
    {
        logoName: 'JavaScript',
        logoLink: '/javascript',
        logoSrc: jsLogo
    },
    {
        logoName: 'React',
        logoLink: '/react',
        logoSrc: reactLogo
    },
    {
        logoName: 'HTML 5',
        logoLink: '/html5',
        logoSrc: htmlLogo
    },
    {
        logoName: 'CSS 3',
        logoLink: '/css3',
        logoSrc: cssLogo
    },
    {
        logoName: 'Node.js',
        logoLink: '/nodejs',
        logoSrc: nodeLogo
    },
    {
        logoName: 'MongoDB',
        logoLink: '/mongodb',
        logoSrc: mongoLogo
    }
]

