const codeSamples = [
    {
        type: 'JavaScript',
        sample: `
const addMessage = async () => {
    try {
        if (message !== '') {
            
            // Add a timestamp to the message
            const dateTime = new Date().toISOString()
            
            // Dispatch ADD_MESSAGE with the timestamp, username, and message
            messageCtx.dispatch({
                type: 'ADD_MESSAGE',
                dateTime,
                userName,
                message
            })
            
            // Reset message state back to an empty string
            setMessage('')
            
            // Focus the message input element
            messageRef.current.focus()
            
            // POST the message data to the API
            const response = await axios.post('https://leewaydb.jlevy.io/messages', {
                dateTime,
                userName,
                message
            })
            
            if (response) {
                // Return the status text from the Axios request
                return response.statusText
            }
        }
    } catch (err) {
        return err
    }
}
`
    },
    {
        type: 'React',
        sample: `
// Component - MBScreen

// Import packages
import React, { useState, useEffect, Fragment } from 'react'
import { motion } from 'framer-motion'
import { format } from 'date-fns'
import useSWR from 'swr'
import axios from 'axios'
import * as Scroll from 'react-scroll'

// Import components
import MBDateLine from './MBDateLine'

// Import contexts
import { useMessages } from '../../../contexts/MessageCtx'

// Import CSS
import '../../../css/MessageBoard.css'

// Import images
import iconComment from '../../../assets/talk.svg'

const fetcher = (url) => axios.get(url).then(r => r.data)

const MBScreen = () => {

    const { state } = useMessages()
    const { dateTime, userName, message } = state
    const { data, mutate } = useSWR('https://leewaydb.jlevy.io/messages', fetcher, )

    const [uniqueDates, setUniqueDates] = useState([])

    const scroll = Scroll.animateScroll

    useEffect(() => {
        if (data && data.length > 0) {
            setUniqueDates(
                [...new Set(data.map(item => format(new Date(item.dateTime), 'iiii, MMMM do')))]
            )
        }
    }, [data])

    useEffect(() => {
        if (dateTime !== '' && userName !== '' && message !== '') {
            console.log('Mutating')
            mutate([...data, {dateTime, userName, message}], false)
        }
    }, [dateTime, userName, message])

    useEffect(() => {
        if (data) {
            console.log(data)
            setTimeout(() => {
                scroll.scrollToBottom({containerId: 'MBScreen', smooth: true, offset: 50})
            }, 500)
        }
    }, [data])

    return (
        <motion.div 
            className='MBScreen'
            id='MBScreen'
            key='MBScreenInner'
        >
        {uniqueDates && uniqueDates.length > 0 && uniqueDates.map((currentDate, index) => {
            return (
                <Fragment key={\`DateWrapper\${index+1}\`}>
                <MBDateLine key={\`DateLine\${index+1}\`} mappedDate={currentDate} />
                {data && data.length > 0 && data
                    .filter(row => format(new Date(row.dateTime), 'iiii, MMMM do') === currentDate)
                    .map((row, index) => {
                    return (
                        <div className='MessageOuter' key={\`Message\${index+1}\`}>
                            <div className='MessageAvatar'>
                                <img src={iconComment} alt='Message icon' />
                            </div>
                            <div className='MessageInner'>
                                <div className='MessageHeader'>
                                    <div className='MessageHeaderUser'>{row.userName}</div>
                                    <div className='MessageHeaderTime'>
                                        {format(new Date(row.dateTime), 'h:mm aaa')}
                                    </div>
                                </div>
                                <div className='MessageBody'>{row.message}</div>
                            </div>
                        </div>
                    )
                })
                }
                </Fragment>
            )
        })}
        </motion.div>
    )
}

export default MBScreen
`
    },
    {
        type: 'HTML 5',
        sample: `
<div className='DateLineWrapper FlexCenter'>
    <svg height='100%' width='40%'>
        <line 
            x1='0' 
            y1='50%' 
            x2='100%' 
            y2='50%' 
            style={{stroke:'#999999', strokeWidth:1}} 
        />
    </svg>
    <div className='DateLineItem FlexCenter'>{mappedDate}</div>
    <svg height='100%' width='40%'>
        <line 
            x1='0' 
            y1='50%' 
            x2='100%' 
            y2='50%' 
            style={{stroke:'#999999', strokeWidth:1}} />
    </svg>
</div>        
`
    },
    {
        type: 'CSS 3',
        sample: `
/* CONTAINER */
.MBContainer {
    grid-area: 4 / 27 / 26 / 51;
    background: rgb(192, 192, 192);
    background: linear-gradient(180deg, rgba(192, 192, 192, 1) 0%, rgba(102, 102, 102, 1) 100%);
    border-top-left-radius: 1vw;
    border-bottom-left-radius: 1vw;
    border: .1vw solid #999999;
    border-right: 0px;
    display: grid;
    grid-template-columns: repeat(30, 1fr);
    grid-template-rows: repeat(20, 1fr);
    grid-column-gap: 0px;
    grid-row-gap: 0px;
    font-size: 2vh;
}

::placeholder {
    /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: #333333;
    opacity: 1;
    /* Firefox */
}

.MBScreenShadow {
    grid-area: 1 / 1 / 19 / 31;
    box-shadow: inset 0 0 .1vw .2vw rgba(0, 0, 0, 0.25);
    margin: .5vw;
    margin-bottom: 0;
    border-radius: 1vw;
}

/* TITLE SECTION */
.MBTitle {
    grid-area: 1 / 1 / 3 / 31;
    display: flex;
    align-items: center;
    border-bottom: 1px solid #888888;
    background-color: #3c4f65;
    border-top-right-radius: 1vw;
    border-top-left-radius: 1vw;
    margin: .5vw;
    margin-bottom: 0;
    padding-left: 1vw;
}

.LeewayLogo {
    height: 3vh;
    filter: invert(100%) sepia(0%) saturate(0%) hue-rotate(345deg) brightness(102%) contrast(103%);
}

.LeewayLogoText {
    padding-left: .5vw;
    height: 3vh;
    font-family: 'CF-Bold';
    font-size: 3vh;
    color: white;
}

/* SCREEN SECTION */
.MBScreen {
    grid-area: 3 / 1 / 19 / 31;
    background-color: #333333;
    border-bottom-right-radius: 1vw;
    border-bottom-left-radius: 1vw;
    margin-left: .5vw;
    margin-right: .5vw;
    border: .1vw solid #333333;
    color: #ffffff;
    overflow-y: auto;
    overflow-x: hidden;
    padding-bottom: 4vh;
}

/* Scrollbar Styling */
.MBScreen::-webkit-scrollbar {
    width: 1vw;
    height: 1vw;
}

.MBScreen::-webkit-scrollbar-track {
    background: gray;
    box-shadow: inset 0 0 .5vw rgba(0, 0, 0, .3);
    border-radius: 1vw;
}

.MBScreen::-webkit-scrollbar-thumb {
    border-radius: 1vw;
    box-shadow: inset 0 0 .5vw rgba(0, 0, 0, .3);
    background-color: #36A1F2;
    border: .1vw solid #333333;
}

.MessageOuter {
    padding: .5vw;
    margin-left: .5vw;
    width: 100%;
    display: flex;
}

.MessageOuter:hover {
    background: #444444;
}

.MessageInner {
    display: flex;
    flex-direction: column;
    margin-left: 1vw;
    width: 100%;
}

.MessageAvatar {
    height: 4vh;
    width: 4vh;
}

.MessageAvatar img {
    height: 100%;
    filter: invert(97%) sepia(99%) saturate(0%) hue-rotate(239deg) brightness(117%) contrast(100%);
}

.MessageHeader {

    display: flex;
    align-items: center;
}

.MessageHeaderUser {
    font-family: 'QS-Bold';
    font-size: 2.25vh;
}

.MessageHeaderTime {
    font-family: 'QS-Light';
    font-size: 1.5vh;
    margin-left: .5vw;
    color: #cccccc;
}

.MessageBody {
    font-family: 'QS-Regular';
    max-width: 95%;
}

.DateLineWrapper {
    height: 6vh;
    width: 100%;
}

.DateLineItem {
    height: 3.5vh;
    width: 30%;
    font-size: 1.5vh;
    border: .2vh solid #999999;
    border-radius: 1vw;
}

/* CONTROLS SECTION */

.MBControls {
    grid-area: 19 / 1 / 21 / 31;
    display: grid;
    grid-template-columns: repeat(30, 1fr);
    grid-template-rows: repeat(2, 1fr);
    grid-column-gap: 0px;
    grid-row-gap: 0px;
    height: 100%;
}

.MBControlsDefault {
    all: unset;
    background: rgb(255, 255, 255);
    background: linear-gradient(180deg, rgba(255, 255, 255, 1) 0%, rgba(221, 221, 221, 1) 100%);
    border: .15vw solid #444444;
    height: 5vh;
    color: #000000;
}

.MBNameIcon {
    grid-area: 1 / 9 / 3 / 11;
    place-self: center;
    background: rgb(255, 255, 255);
    background: linear-gradient(180deg, rgba(255, 255, 255, 1) 0%, rgba(221, 221, 221, 1) 100%);
    border: .15vw solid #444444;
    border-right: 0vw;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 5vh;
    border-top-left-radius: 1vw;
    border-bottom-left-radius: 1vw;
}

.MBNameIcon img {
    height: 4vh;
}

.MBName {
    grid-area: 1 / 11 / 3 / 23;
    display: flex;
    justify-content: center;
    align-items: center;
}

.MBName input {
    border-top-right-radius: 1vw;
    border-bottom-right-radius: 1vw;
    padding-left: 1vw;
    width: 100%;
    height: 5vh;
}

.MBEmoji {
    grid-area: 1 / 3 / 3 / 5;
    display: flex;
    align-items: center;
}

.MBEmoji button {
    width: 100%;
    border-top-left-radius: 1vw;
    border-bottom-left-radius: 1vw;
    border-right: 0vw;
}

.MBEmoji button:hover {
    cursor: pointer;
    background: #cccccc;
}

.MBEmoji button:focus {
    background: #B200FD;
}

.MBEmoji img {
    height: 3vh;
    filter: drop-shadow(0px 1px .5px rgba(0, 0, 0, .5));
}

.MBPicker {
    grid-area: 1 / 3 / 3 / 15;
    z-index: 2;
    display: flex;
    align-items: flex-end;
}

.MBLoading {
    grid-area: 1 / 3 / 3 / 15;
    z-index: 2;
    height: 320px;
    width: 280px;
    background-color: #cccccc;
    color: #333333;
    border: 2px solid #333333;
}

.MBMessage {
    grid-area: 1 / 5 / 3 / 27;
    display: flex;
    align-items: center;
}

.MBMessage input {
    border-right: 0px;
    padding-left: .5vw;
    width: 100%;
}

.MBSend {
    grid-area: 1 / 27 / 3 / 29;
    display: flex;
    align-items: center;
}

.MBSend button {
    border-top-right-radius: 1vw;
    border-bottom-right-radius: 1vw;
    width: 100%;
}

.MBSend button:hover {
    cursor: pointer;
    background: #cccccc;
}

.MBSend button:focus {
    background: #B200FD;
}

.MBSend img {
    height: 3vh;
    filter: drop-shadow(0px 1px .5px rgba(0, 0, 0, .5));
}
        `
    },
    {
        type: 'Node.js',
        sample: `
import express from 'express'
import rateLimit from 'express-rate-limit'
import apicache from 'apicache'
import cors from 'cors'
import helmet from 'helmet'
import bodyParser from 'body-parser'
import readMessages from './dbFunctions/readMessages'
import writeMessages from './dbFunctions/writeMessages'

// Set up for Express server
const port = process.env.EXPRESS_PORT || 5001
const app = express()
let cache = apicache.middleware

// Set rate limit to 25 requests per 15mins per IP
const limiter = rateLimit({
    windowMs: 15 * 60 * 1000,
    max: 25
})

app.use(cors())
app.use(helmet())
app.use(limiter)

// Set increased limit on bodyParser to allow for large datasets
app.use(bodyParser.json({limit: '50mb', extended: true}))

/*********************************************
            BEGIN: V1 API endpoints
*********************************************/
app.get('/api', (req, res) => {
    res.json({"message": "success"})
})
/* GET messages */
app.get('/messages', async (req, res) => {
    try {
        const results = await readMessages()
            if (results !== 'error') {
                console.log(results)
                res.json(results)
            } else {
                res.json('error')
            }
    } catch (err) {
        res.send(err)
    }
})

/* POST messages */
app.post('/messages', async (req, res) => {
    try {
        const results = await writeMessages(req.body)
            if (results !== 'error') {
                console.log(results)
                res.json(results)
            } else {
                res.json('error')
            }
    } catch (err) {
        res.send(err)
    }
})

/*********************************************
            END: V1 API endpoints
*********************************************/

console.log('App started on port 5001')
app.listen(port)
        `
    },
    {
        type: 'MongoDB',
        sample: `
import { MongoClient } from 'mongodb'

export default async function readMessages () {

    try {
        const uri = \`mongodb+srv://\${process.env.DB_USER}:\${encodeURI(process.env.DB_PASSWORD)}@xxxxx.mongodb.net/leeway?retryWrites=true&w=majority\`
        const client = new MongoClient(uri, { useNewUrlParser: true, useUnifiedTopology: true })
        const connection = await client.connect()

        if (connection) {
            const collection = client.db("leeway").collection("messages")
            //return all docs
            const query = {}
            const cursor = await collection.find(query).toArray()

            if (cursor) {
                await connection.close()
                return cursor
            }
        }
    } 
    
    catch (err) {
        console.log(err)
        return 'error'
    }
}
        `
    }
]

export default codeSamples