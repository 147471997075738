// Component - MBScreen

// Import packages
import React, { useState, useEffect, Fragment } from 'react'
import { motion } from 'framer-motion'
import { format } from 'date-fns'
import useSWR from 'swr'
import axios from 'axios'
import * as Scroll from 'react-scroll'

// Import components
import MBDateLine from './MBDateLine'

// Import contexts
import { useMessages } from '../../../contexts/MessageCtx'

// Import CSS
import '../../../css/MessageBoard.css'

// Import images
import iconComment from '../../../assets/talk.svg'

const fetcher = (url) => axios.get(url).then(r => r.data)

const MBScreen = () => {

    const { state } = useMessages()
    const { dateTime, userName, message } = state
    const { data, mutate } = useSWR('https://leewaydb.jlevy.io/messages', fetcher)

    const [uniqueDates, setUniqueDates] = useState([])

    const scroll = Scroll.animateScroll

    useEffect(() => {
        if (data && data.length > 0) {
            setUniqueDates([...new Set(data.map(item => format(new Date(item.dateTime), 'iiii, MMMM do')))])
        }
    }, [data])

    useEffect(() => {
        if (dateTime !== '' && userName !== '' && message !== '') {
            console.log('Mutating')
            mutate([...data, {dateTime, userName, message}], false)
        }
    }, [dateTime, userName, message])

    useEffect(() => {
        if (data) {
            console.log(data)
            setTimeout(() => {
                scroll.scrollToBottom({containerId: 'MBScreen', smooth: true, offset: 50})
            }, 500)
        }
    }, [data])

    return (
        <motion.div 
            className='MBScreen'
            id='MBScreen'
            key='MBScreenInner'
        >
        {uniqueDates && uniqueDates.length > 0 && uniqueDates.map((currentDate, index) => {
            return (
                <Fragment key={`DateWrapper${index+1}`}>
                <MBDateLine key={`DateLine${index+1}`} mappedDate={currentDate} />
                {data && data.length > 0 && data.filter(row => format(new Date(row.dateTime), 'iiii, MMMM do') === currentDate).map((row, index) => {
                    return (
                        <div className='MessageOuter' key={`Message${index+1}`}>
                            <div className='MessageAvatar'>
                                <img src={iconComment} alt='Message icon' />
                            </div>
                            <div className='MessageInner'>
                                <div className='MessageHeader'>
                                    <div className='MessageHeaderUser'>{row.userName}</div>
                                    <div className='MessageHeaderTime'>{format(new Date(row.dateTime), 'h:mm aaa')}</div>
                                </div>
                                <div className='MessageBody'>{row.message}</div>
                            </div>
                        </div>
                    )
                })
                }
                </Fragment>
            )
        })}
        </motion.div>
    )
}

export default MBScreen