//Packages
import React, { useEffect, useState } from 'react'
import { isBrowser, isMobileOnly, deviceType } from 'react-device-detect'
import useSWR from 'swr'
import axios from 'axios'
//Components
import DesktopContainer from './components/Desktop/DesktopContainer'
//import LoadingModal from './components/Desktop/LoadingModal'
import { useGlobalState } from './contexts/State'
//Styles
import './App.css'

const fetcher = (url) => axios.get(url).then(r => r.data)

const App = () => {
    
    const [loaded, setLoaded] = useState(false)
    const globalState = useGlobalState()
    const { data, mutate } = useSWR('https://leewaydb.jlevy.io/messages', fetcher)
    //const { flipped, skipIntro } = globalState.state

    useEffect(() => {
        globalState.dispatch({type: 'SKIP_INTRO'})
    }, [])

    return (
                <div className='App'>
                    {/* <Intro flipped={flipped} /> */}
                    {isBrowser && (
                        <DesktopContainer />
                    )}
                    {isMobileOnly && (
                        <div className='Mobile FlexCenter'>Mobile Support currently unavailable</div>
                    )}
                </div>
            
            
    )
}

export default App
