import React, { Fragment, useState, useEffect, useLayoutEffect, useRef, lazy, Suspense } from 'react'
import { motion } from 'framer-motion'
import axios from 'axios'
import LoadingModal from '../LoadingModal'
import { useMessages } from '../../../contexts/MessageCtx'
import IconEmoji from '../../../assets/smile.svg'
import IconSend from '../../../assets/send.svg'
import IconUser from '../../../assets/name.svg'
import '../../../css/MessageBoard.css'

const Picker = lazy(() => import('emoji-picker-react'))

const MBControls = () => {

    const nameRef = useRef()
    const messageRef = useRef()
    const messageCtx = useMessages()
    const [message, setMessage] = useState('')
    const [userName, setUserName] = useState('')
    const [tempName, setTempName] = useState('')
    const [showPicker, setShowPicker] = useState(false)
    const [chosenEmoji, setChosenEmoji] = useState(null)
    const [showWarning, setShowWarning] = useState(false)

    const onEmojiClick = (event, emojiObject) => {
        setChosenEmoji(emojiObject);
        
    }

    useEffect(() => {
        if (chosenEmoji) {
            setMessage(`${message}${chosenEmoji.emoji}`)
            setChosenEmoji(null)
            setShowPicker(false)
        }
    }, [chosenEmoji])

    const handleChange = (e) => {
        if (e.target.id === 'MBName') {
            setTempName(e.target.value)
        }
        if (e.target.id === 'MBMessage') {
            setMessage(e.target.value)
        }
    }

    const handleReturn = (e) => {
        if (e.key === 'Enter') {
            addMessage()
        }
    }
    const handleUser = (e) => {
        if (e.key === 'Enter') {
            setUserName(tempName)
        }
    }

    const addMessage = async () => {
        try {
            if (userName === '') {
                setUserName('Anonymous')
            }

            if (message !== '') {
                const dateTime = new Date().toISOString()
                messageCtx.dispatch({type: 'ADD_MESSAGE', dateTime, userName, message})
                setMessage('')
                messageRef.current.focus()
                const response = await axios.post('https://leewaydb.jlevy.io/messages', {dateTime, userName, message})
                if (response) {
                    console.log(response.statusText)
                } 
            }
        } catch (err) {
            throw err
        }
    }

    useLayoutEffect(() => {
        
            setTimeout(() => {
                if (nameRef.current) {
                    nameRef.current.focus()
                }
            }, 1000)
        
    }, [nameRef])

    return (
        <Fragment>
            <div className='MBControls'>
        {!userName ? (
            <Fragment>
            <motion.div
                className='MBNameIcon'
                key='MBNameIcon'
                animate={{opacity: 1}}
                initial={{opacity: 0}}
                exit={{opacity: 0, transition: {delay: 0, duration: 1}}}
                transition={{delay: 1, duration: .5}}
            >
                <img src={IconUser} alt='User icon' />
            </motion.div>
            <motion.div
                className='MBName'
                key='MBName'
                animate={{opacity: 1}}
                initial={{opacity: 0}}
                exit={{opacity: 0, transition: {delay: 0, duration: 1}}}
                transition={{delay: 1, duration: .5}}
            >
                <input
                    id='MBName'
                    ref={nameRef}
                    className='MBControlsDefault' 
                    value={tempName}
                    onChange={(e) => handleChange(e)}
                    onKeyPress={(e) => handleUser(e)}
                    placeholder='Enter your name to leave a message...'
                    maxLength={40}
                    required
                />
            </motion.div>
            </Fragment>
            ) : (
            <Fragment>
            <motion.div
                className='MBEmoji'
                key='MBEmoji'
                animate={{opacity: 1}}
                initial={{opacity: 0}}
                exit={{opacity: 0, transition: {delay: 0}}}
                transition={{delay: 0, duration: .5}}
            >
                <button 
                    className='MBControlsDefault FlexCenter'
                    onClick={() => setShowPicker(!showPicker)}
                ><img src={IconEmoji} alt='Emoji icon' /></button>
            </motion.div>
            <motion.div
                className='MBMessage'
                key='MBMessage'
                animate={{opacity: 1}}
                initial={{opacity: 0}}
                exit={{opacity: 0, transition: {delay: 0}}}
                transition={{delay: 0, duration: .5}}
            >
                <input 
                    className='MBControlsDefault'
                    id='MBMessage'
                    ref={messageRef}
                    value={message}
                    onChange={(e) => handleChange(e)}
                    onKeyPress={(e) => handleReturn(e)}
                    placeholder='Type a message...'
                    maxLength={140}
                    autoFocus
                />
            </motion.div>
            <motion.div
                className='MBSend'
                key='MBSend'
                animate={{opacity: 1}}
                initial={{opacity: 0}}
                exit={{opacity: 0, transition: {delay: 0}}}
                transition={{delay: 0, duration: .5}}
            >
                <button 
                    className='MBControlsDefault FlexCenter'
                    onClick={addMessage}
                    disabled={message === ''}
                ><img src={IconSend} alt='Send message icon' /></button>
            </motion.div>
            
            </Fragment>
        )}
        {showPicker && (
            <div className='MBPicker'>
                <Suspense fallback={
                    <motion.div 
                        className='MBLoading FlexCenter'
                        key='MBLoading'about
                    >Loading...</motion.div>
                }>
                    <Picker 
                        onEmojiClick={onEmojiClick} 
                        preload={false}
                        disableSearchBar={true} 
                    />
                </Suspense>
            </div>
        )}
        </div>
    </Fragment>
    )
}

export default MBControls