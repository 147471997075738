import React, { Fragment } from 'react'
import { useGlobalState } from '../../contexts/State'
import { motion } from 'framer-motion'
import Logo from './Logo'
import { logoItems } from './LogoData'

const Logos = () => {
    const globalState = useGlobalState()
    const { flipped, skipIntro } = globalState.state

    return (
        <Fragment>
            {logoItems.map((logoItem, index) => {
                return (
                    <Fragment key={`LogoFragment${index+1}`}>
                        <Logo 
                            key={`${logoItem.logoName}${index+1}`}
                            logoSrc={logoItem.logoSrc} 
                            logoLink={logoItem.logoLink} 
                            logoName={logoItem.logoName} 
                            index={index} 
                        />
                        <motion.div
                            key={`LogoText${index+1}`} 
                            className={`LogoText LogoText${index+1}`}
                            initial={{scale: 0, opacity: 0}}
                            animate={{scale: 1, opacity: 1}}
                            transition={skipIntro ? { delay: index*.1} : { delay: index*.3 + 2}}
                            style={flipped ? {color: '#000000'} : {color: '#ffffff'}}
                            exit={{scale: 0, opacity: 0, transition: { delay: 0, duration: .5}}}
                        >
                            {logoItem.logoName}
                        </motion.div>
                    </Fragment>
                )
            })}
        </Fragment>
    )
}

export default Logos
