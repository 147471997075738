import React from 'react'
import { motion } from 'framer-motion'
import { Link } from 'react-router-dom'
import { useGlobalState } from '../../contexts/State'

const Logo = (props) => {

    const globalState = useGlobalState()
    const { skipIntro } = globalState.state
    const { logoSrc, logoName, logoLink, index } = props
    
    const handleTab = () => {
        console.log('Changing tab')
        console.log(logoName, logoLink, logoSrc)
        globalState.dispatch({type: 'SET_ACTIVE_TAB', logoName, logoSrc, logoLink })
    }

    return (
        <motion.div
            key={`Logo${index+1}`}
            className={`Logo LogoPosition${index+1}`} 
            initial={skipIntro ? {y: 0, opacity: 0} : {y: 200, opacity: 0}}
            whileHover={{ 
                scale: 1.5,
                y: -30,
                transition: { duration: .15 }
            }}
            animate={skipIntro ? {y: 0, opacity: 1, transition: { delay: index*.05}} : { y: 0, opacity: 1, transition: { delay: index*.15 + 2}}}
            exit={{scale: 0, opacity: 0, transition: { delay: 0, duration: .5}}}
            onClick={() => handleTab()}
        >
            <Link to={`${logoLink}`}>
                <img key={logoSrc} src={logoSrc} alt={`${logoName} Logo`} />
            </Link>
        </motion.div>
    )
}

export default Logo