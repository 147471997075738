import React from 'react'
//import { useGlobalState } from '../../contexts/State'
import { motion } from 'framer-motion'

const NavMenu = () => {
    //const globalState = useGlobalState()
    //const { flipped } = globalState.state
    const menuItems = ['Contact']

    return (
        <div className='NavMenu'>
            {menuItems.map((item, index) => {
                return (
                    <motion.div
                        key={`Menu${index}`}
                        className='MenuItem'
                        initial={{y: '-200%'}}
                        animate={{y: 0}}
                        transition={{type: 'spring', delay: index/4}}
                    >
                        <a 
                            href='mailto://jason@jlevy.io'
                            style={{ color: '#ffffff', textDecoration: 'none' }}    
                        >{item}</a>
                    </motion.div>
                )
            })}
        </div>
    )
}

export default NavMenu