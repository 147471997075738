import React, { useEffect } from 'react'
import { Route, Switch, useLocation } from 'react-router-dom'
import { AnimatePresence } from 'framer-motion'
import { useGlobalState } from '../../contexts/State'
import { logoItems } from './LogoData'
//import WindowLeft from './WindowLeft'
//import WindowRight from './WindowRight'
import HeroText from './HeroText'
import Logos from './Logos'
import NavLogo from './NavLogo'
import NavMenu from './NavMenu'
import Page from './Page'
import '../../css/DesktopContainer.css'

const DesktopContainer = () => {

    const globalState = useGlobalState()
    const { activeTab, activeLink } = globalState.state
    const location = useLocation()

    useEffect(() => {
        //console.log(`Location: ${JSON.stringify(location)}`)
        if (location.pathname !== '/') {
            if (activeLink !== location.pathname) {
                //console.log('Path does not match')
                const data = logoItems.find(({ logoLink }) => logoLink === location.pathname)
                
                if (data) {
                    //console.log(data)
                    //console.log('Updating link')
                    const { logoName, logoLink, logoSrc } = data
                    globalState.dispatch({type: 'SET_ACTIVE_TAB', logoName, logoSrc, logoLink })
                    //console.log(globalState.state)
                } else {
                    window.location.replace('/')
                }
                
            } else {
                //console.log('Path matches')
            }
        }
    }, [activeLink, activeTab])

    return (
        <div className='DesktopContainer'>
            {/*<div className='ActiveTab'>{activeTab}</div>*/}
            <NavLogo />
            <NavMenu />
            <AnimatePresence exitBeforeEnter>
                <Switch location={location} key={location.key}>
                    <Route exact path='/'>
                        <HeroText key='HeroText' />
                        <Logos key='Logos' />         
                    </Route>
                    <Route path={`${activeLink}`}>
                        <Page key={`Page-${activeTab}`} />
                    </Route>
                </Switch>
            </AnimatePresence>
            {/*
            <WindowLeft />
            <WindowRight />*/}
        </div>
    )
}

export default DesktopContainer