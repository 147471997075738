import React from 'react'
import { motion } from 'framer-motion'
import MBScreen from './MBScreen'
import '../../../css/MessageBoard.css'
import MBControls from './MBControls'
import LeewayLogo from '../../../assets/slack-filled.svg'

const MBContainer = () => {

    return (
        <motion.div 
            className='MBContainer'
            key='MBdiv'
            animate={{x: 0, rotateZ: 0}}
            initial={{x: 1000, rotateZ: -90}}
            exit={{x: 1000, rotateZ: -90}}
            transition={{duration: 1}}
        >
            <div className='MBTitle'>
                <img className='LeewayLogo' src={LeewayLogo} alt='Leeway Logo' />
                <div className='LeewayLogoText glow'>leeway</div>
            </div>
            <MBScreen key='MBScreen' />
            <div className='MBScreenShadow'></div>
            <MBControls key='MBControls' />
        </motion.div>
    )
}

export default MBContainer