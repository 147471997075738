import React, {createContext, useContext, useReducer} from 'react'

const messageState = {
    dateTime: '',
    userName: '',
    message: '',
    currentDate: ''
}

const messageCtx = createContext(messageState)
const { Provider } = messageCtx

const reducer = (state, action) => {

    switch(action.type) {
        case 'ADD_MESSAGE':
            return { ...state, dateTime: action.dateTime, userName: action.userName, message: action.message }
        case 'UPDATE_CURRENT_DATE':
            return { ...state, currentDate: action.currentDate }
        default:
            throw new Error()
    }
}
const MessageProvider = ( { children } ) => {
    const [state, dispatch] = useReducer(reducer, messageState)

    return <Provider value={{ state, dispatch }}>{children}</Provider>
}

export default MessageProvider
export const useMessages = () => useContext(messageCtx)