import React, { Fragment, useState } from 'react'
import { useGlobalState } from '../../contexts/State'
import { motion } from 'framer-motion'
import HeroImage from '../../assets/jlevy_headshot.jpg'
const HeroText = () => {
    const globalState = useGlobalState()
    const { flipped, skipIntro } = globalState.state

    return (
        <Fragment>
            <motion.div
                key='HeroImage'
                className='HeroImage'
                animate={{x: 0, opacity: 1}}
                initial={skipIntro ? {x: 0, opacity: 0} : {x: '-500%', opacity: 0}}
                exit={{opacity: 0, transition: {duration: .25, delay: 0}}}
                transition={{
                    type: 'spring', 
                    damping: 15,
                    delay: 0}}
            >
                <img src={HeroImage} alt='Jason Levy headshot' />
            </motion.div>
            
            <motion.div
                key='HeroTextTop'
                className='HeroText HeroTextTop'
                initial={skipIntro ? false : {scale: 0, opacity: 1, color: '#000000'}}
                animate={flipped ? {scale: 1, opacity: 1, color: '#000000'} : {scale: 1, opacity: 1, color: '#ffffff'}}
                transition={skipIntro ? { delay: 0, duration: .5} : { delay: 1, duration: .5}}
                exit={{opacity: 0, transition: {duration: .25, delay: 0}}}
                >
                Hi, I'm Jason!
            </motion.div>
            <motion.div
                key='HeroTextBottom'
                className='HeroText HeroTextBottom'
                initial={skipIntro ? false : {scale: 0, opacity: 1, color: '#000000'}}
                animate={flipped ? {scale: 1, opacity: 1, color: '#000000'} : {scale: 1, opacity: 1, color: '#ffffff'}}
                transition={skipIntro ? { delay: 0, duration: .5} : { delay: 1.5, duration: .5}}
                exit={{opacity: 0, transition: {duration: .25, delay: 0}}}
                >
                <div>I build modern</div>
                <motion.div
                    className='HeroTextHighlight glow'
                    key='WebApps'
                    style={{fontFamily: 'QS-Bold'}} 
                    initial={{color: '#000000', scale: 1}}
                    animate={flipped ? {color: '#e6f5ff'} : {color: '#00ffff'}}
                    transition={{ duration: 2 }}>
                    &nbsp;Web Apps&nbsp;
                </motion.div>
                <div>with:</div> 
            </motion.div>
        </Fragment>
    )
}

export default HeroText