import React, { useEffect, useState } from 'react'
import { motion } from 'framer-motion'
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter';
import { tomorrow } from 'react-syntax-highlighter/dist/esm/styles/prism'
import { useGlobalState } from '../../../contexts/State'
import '../../../css/CodeWindow.css'
import codeSamples from './CWSamples'

const CWContainer = () => {

    const globalState = useGlobalState()
    const { activeTab, logoSrc } = globalState.state
    const [sample, setSample] = useState(``)

    useEffect(() => {
        console.log('Active Tab:', activeTab)
        if (activeTab) {
            const test = codeSamples.filter(item => item.type === activeTab)
            console.log('test:', test)
            if (test.length > 0) {
                setSample(test[0].sample)
            }
            
        }
    }, [activeTab]) 

    useEffect(() => {
        console.log('Sample:', sample)
    }, [sample])

    return (
        <motion.div 
            className='CWContainer'
            key='CWdiv'
            animate={{x: 0, rotateZ: 0}}
            initial={{x: -1000, rotateZ: 90}}
            exit={{x: -1000, rotateZ: 90}}
            transition={{duration: 1}}
        >
            <div className='CWTitle FiraBold'>
                <div>{`Code Sample - ${activeTab}`}</div>
            </div>
            <div className='CWScreen'>
                <div className='CWDescription'>Leeway is a light-weight Slack clone that utilizes React, Node.js, Express and MongoDB.  Please feel free to leave a message :)</div>
                    {activeTab === 'JavaScript' && (
                        <SyntaxHighlighter language='javascript' style={tomorrow}>
                            {sample}
                        </SyntaxHighlighter>
                    )}
                    {activeTab === 'React' && (
                        <SyntaxHighlighter language='jsx' style={tomorrow}>
                            {sample}
                        </SyntaxHighlighter>
                    )}
                    {activeTab === 'HTML 5' && (
                        <SyntaxHighlighter language='markup' style={tomorrow}>
                            {sample}
                        </SyntaxHighlighter>
                    )}
                    {activeTab === 'CSS 3' && (
                        <SyntaxHighlighter language='css' style={tomorrow}>
                            {sample}
                        </SyntaxHighlighter>
                    )}
                    {activeTab === 'Node.js' && (
                        <SyntaxHighlighter language='javascript' style={tomorrow}>
                            {sample}
                        </SyntaxHighlighter>
                    )}
                    {activeTab === 'MongoDB' && (
                        <SyntaxHighlighter language='javascript' style={tomorrow}>
                            {sample}
                        </SyntaxHighlighter>
                    )}

                    
                
            </div>
            <div className='CWControls'>

            </div>
        </motion.div>
    )
}

export default CWContainer