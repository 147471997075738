import React, {createContext, useContext, useReducer} from 'react'

const initialState = {
    flipped: false,
    activeTab: 'Home',
    activeLink: '',
    logoSrc: '',
    skipIntro: false
}

const globalState = createContext(initialState)
const { Provider } = globalState

const reducer = (state, action) => {

    switch(action.type) {
        case 'FLIP_UI':
            return { ...state, flipped: !state.flipped }
        case 'SET_ACTIVE_TAB':
            return { ...state, activeTab: action.logoName, activeLink: action.logoLink, logoSrc: action.logoSrc}
        case 'SKIP_INTRO':
            return { ...state, skipIntro: true}
        default:
            throw new Error()
    }
}
const StateProvider = ( { children } ) => {
    const [state, dispatch] = useReducer(reducer, initialState)

    return <Provider value={{ state, dispatch }}>{children}</Provider>
}

export default StateProvider
export const useGlobalState = () => useContext(globalState)