import React from 'react'
import { Link } from 'react-router-dom'
import jlLogo from '../../assets/jl-logo.svg'

const SiteLogo = () => {

    return (
        <div className='NavLogo'>
            
                <img src={jlLogo} alt='jLevy.io logo' />
                <div className='NavLogoText'><Link to='/' style={{ textDecoration: 'none' }}>jLevy.io</Link></div>
            
        </div>
    )
}

export default SiteLogo